import axios from "axios";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { Checkmark } from "react-checkmark";
import Canvas from "./Pages/Canvas";
import discordLogo from "./assets/discord.png";
import telegram from "./assets/telegram.png";
import Failed from "./assets/failedtransaction.png";
import duckyLogo from "./assets/logo.png";
import instaLogo from "./assets/twitter.png";

import "./App.css";

function App(){
  const collections = [
    "banshees",
    "cryptoCrew",
    "drillClub",
    "genK",
    // "highSeasPirates",
    "pluto",
    "rektDogs",
    "rektGang",
    "rivalBears",
    "spaceSkellies",
  ];

  const collectionData = {
    banshees: {
      supply: 4444,
      twitter: "BansheesNFT_",
      startingURL: "https://bafybeieksn2g5qxbkfkblnp3arvnsbgagby4rilhgr3gbieglmr6vm4quy.ipfs.nftstorage.link/41.png?ext=png",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    } ,
    cryptoCrew: {
      supply: 7777,
      twitter: "cryptocrewnfts",
      startingURL: "https://ipfs.io/ipfs/QmW4psWgE8VyNAPzE1YPU5RBfxbgWNBrQQeknUQQuccUPD/1.png",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    },
    drillClub: {
      supply: 6969,
      twitter: "DrillClubNFT",
      startingURL: "https://ipfs.io/ipfs/Qmcc8QMtPMbmhRP8MuAGx5EY3xkNdGqhVVBfpvpYHdvDL5/6721.png",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    },
    genK: {
      supply: 5555,
      twitter: "GenK_NFT",
      startingURL: "https://bafybeict3o7ygtdrn6wdexkl7yzdzdhr2cxrdnwzwoakofbxom4cj234zu.ipfs.nftstorage.link/59.jpg?ext=jpg",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    },
    // highSeasPirates: {
    //   supply: 1186,
    //   twitter: "HighSeasGameFi",
    //   startingURL: "https://ipfs.io/ipfs/bafybeibcym4me3kt3liehx3ivuiiob5kaubr2b2o2q25k44t3qywewgxae/129.jpg",
    //   1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
    //   2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    // },
    pluto: {
      supply: 4444,
      twitter: "plutomisfits",
      startingURL: "https://ik.imagekit.io/plutomisfits/151.png",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    },
    rektDogs: {
      supply: 10000,
      twitter: "rektdogs",
      startingURL: "https://metadata.rektdogs.com/rd/1.png",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    },
    rektGang: {
      supply: 1097,
      twitter: "rekt_gang",
      startingURL: "https://ipfs.io/ipfs/QmXuBTjpebHtHmteQcctJKTCDcqVA8pqyshi5Pdg6avREY/151.png",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    },
    "rivalBears": {
      supply: 5000,
      twitter: "RivalBearNFT",
      startingURL: "https://bafybeia4nunonqfxsftmppzwbngihxslnoeer6om3kgjr2jvbdjusbxjhm.ipfs.nftstorage.link/234.jpg?ext=jpg",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    },
    spaceSkellies: {
      supply: 3500,
      twitter: "SpaceSkellies",
      startingURL: "https://ipfs.io/ipfs/bafybeiavall5udkxkdtdm4djezoxrmfc6o5fn2ug3ymrlvibvwmwydgrkm/2510.jpg",
      1: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme1Final.png",
      2: "https://maxinbodyshop.s3.us-east-2.amazonaws.com/rubber/meme2Final.png",
    },
  };

  const [currentCollection, setCurrentCollection] = useState("banshees");
  const [tokenid, setTokenid] = useState(0);
  const [tokenURL, setTokenURL] = useState(collectionData[currentCollection].startingURL);
  const [allMemes, setAllMemes] = useState([]);
  const [selectedMeme, setSelectedMeme] = useState({});
  const [imageArray, setImageArray] = useState([collectionData[currentCollection].startingURL]);
  const imgRef = useRef();

  const [popup, setPopup] = useState(false);
  const [popupState, setPopupState] = useState("default");

  const [overlayTransformations, setOverlayTransformations] = useState({});
  const [memeToggle, setMemeToggle] = useState(false);

  useEffect(() => {
    setAllMemes([
      { memeURL: collectionData[currentCollection]["1"], id: 1 },
      { memeURL: collectionData[currentCollection]["2"], id: 2 },
    ]);
  }, []);

  function handleChange(event) {
    setTokenid(event.target.value);
  }

  const createHash = () => {
    let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < 30; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  const handleDownloadAndTweet = async () => {
    setPopupState("creatingImage");
    setPopup(true);

    var data = JSON.stringify({
      action: "rubberDownload",
      imageArray: imageArray,
      identifyingHash: createHash(),
      overlayTransformations: overlayTransformations,
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(async (response) => {
        let blob = await fetch(response.data.imageLink).then((r) => r.blob());
        await saveAs(blob, currentCollection + "_meme.png");

        let twitterAt = "RubberDuckyCoin";
        let websiteLink = "https://www.body-shop.xyz/";
        const tweetText = `Just rubbered up my @${collectionData[currentCollection].twitter}! \n@${twitterAt} to the moon! \n\nYou can do the same below 😉: ${websiteLink}`;
        const imageUrl = `${websiteLink}`;
        const mediaId = "1646561367388717064";
        const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
        window.open(tweetUrl, "_blank");
        setPopupState("successfulCreation");
      })
      .catch(function (error) {
        setPopupState("transactionError");
      });
  };

  const renderPopup = () => {
    if (popupState === "creatingImage") {
      return (
        <div style={{ position: "fixed", bottom: 35, right: 35, zIndex: 100 }}>
          <div className="bg-gray rounded-md p-4 flex items-center">
            <img
              className="h-5 w-5 mr-4 animate-spin"
              src="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.16.1/images/loader-large.gif"
              alt="Loading"
            />
            <p className="text-white font-lekton">Downloading Image...</p>
          </div>
        </div>
      );
    } else if (popupState === "transactionError") {
      const timer = setTimeout(() => {
        resetPopup();
      }, 5000);

      return (
        <div style={{ position: "fixed", bottom: 35, right: 35, zIndex: 100 }}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img className="h-5 w-5 mr-4" src={Failed} alt="Loading" />
              <p className="text-white font-lekton mr-2">There was an issue. Please Try Again.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray">
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    } else if (popupState === "successfulCreation") {
      const timer = setTimeout(() => {
        resetPopup();
      }, 5000);

      return (
        <div style={{ position: "fixed", bottom: 35, right: 35, zIndex: 100 }}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <Checkmark size="24px" />
              <p className="text-white font-lekton ml-2 mr-2">
                Image downloaded. Tweet your Creation!
              </p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray">
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    } else if (popupState === "invalidID") {
      const timer = setTimeout(() => {
        resetPopup();
      }, 5000);

      return (
        <div style={{ position: "fixed", bottom: 35, right: 35, zIndex: 100 }}>
          <>
            <div className="bg-gray rounded-md rounded-b-none p-4 flex items-center">
              <img className="h-5 w-5 mr-4" src={Failed} alt="Loading" />
              <p className="text-white font-lekton mr-2">Invalid Token ID.</p>
              <button
                onClick={() => {
                  resetPopup();
                }}
                className="text-white font-lekton bg-gray rounded-full w-5 h-5 flex items-center justify-center hover:bg-dark-gray">
                &#10761;
              </button>
            </div>
            <div className="relative w-full h-1 bg-primary-red bg-opacity-30">
              <div className="absolute left-0 top-0 h-1 bg-primary-red animate-progress" />
            </div>
          </>
        </div>
      );
    }
  };

  const resetPopup = (reason) => {
    setPopup(false);
    setPopupState("default");
  };

  const renderWithTokenid = async () => {
    if (tokenid >= 1 && tokenid < collectionData[currentCollection].supply) {
      var data = JSON.stringify({
        currentCollection: currentCollection,
        tokenID: tokenid,
        action: "rubberTokenID",
      });

      var config = {
        method: "post",
        url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding",
        headers: {
          "x-api-key": process.env.GATEWAY_KEY,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setTokenURL(response.data.imageLink.imageURL);
          setImageArray(response.data.imageLink.imageURL);
          setSelectedMeme({});
        })
        .catch(function (error) {
          setPopupState("transactionError");
        });
    } else {
      setPopupState("invalidID");
      setPopup(true);
    }
  };

  const handleCollectionSwitch = (collection) => {
    setCurrentCollection(collection);
    setTokenURL(collectionData[collection].startingURL);
    setSelectedMeme({});
  };

  const renderWithMeme = async (meme) => {
    setSelectedMeme(meme);
    setImageArray([tokenURL, meme.memeURL]);
  };

  const searchForNewTokenid = () => {
    var data = JSON.stringify({
      collectionName: currentCollection,
      tokenid: tokenid,
      action: "rubberGenerator",
    });

    var config = {
      method: "post",
      url: "https://rmbl36wkd5.execute-api.us-east-1.amazonaws.com/Production/polyonboarding",
      headers: {
        "x-api-key": process.env.GATEWAY_KEY,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setTokenURL(response.data.tokenURL);
      })
      .catch(function (error) {
        setPopupState("transactionError");
      });
  };

  const [showMenu, setShowMenu] = useState(false);
  const [showMemeButton, setShowMemeButton] = useState(true);
  useEffect(() => {
    if (window && window.innerWidth > 1023) {
      setShowMenu(true);
      setShowMemeButton(true);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1023) {
        setShowMenu(true);
        setShowMemeButton(true);
      } else {
        setShowMenu(false);
        setShowMemeButton(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearCanvas = () => {
    setSelectedMeme({});
  }

  return (
    <section className="h-screen flex-grow flex-col flex p-5 xl:px-10 py-5 bg-darkblue overflow-hidden">
      {popup ? renderPopup() : <div></div>}
      <button
        onClick={() => {
          setShowMenu(true);
        }}
        className="absolute top-2 left-1/2 -translate-x-1/2 text-sm text-black font-bold  lg:hidden z-20 font-rubber bg-yellow px-2 py-1 rounded-full yt">
        Change collection
      </button>
      <div className="flex h-full overflow-hidden gap-20">
        <div
          className={` w-full  lg:w-1/5 flex flex-col lg:h-auto overflow-hidden gap-10 fixed lg:static bottom-0 left-0  bg-darkblue z-30 py-5 lg:p-0 transition-all max-lg:shadow-thick ${
            showMenu ? "translate-y-0" : "translate-y-full"
          }`}>
          <button
            onClick={() => {
              setShowMenu(false);
            }}
            className="absolute top-2 right-2 text-white text-2xl font-bold rotate-45 lg:hidden">
            +
          </button>
          <div className="flex flex-col items-center lg:gap-5 text-lg xl:text-3xl 2xl:text-4xl font-rubber font-bold text-bold text-yellow">
            <p>Rubber Duck</p>
            <p>Meme Booth</p>
          </div>
          <div className="flex flex-col justify-between items-center my-auto  h-1/2 flex-grow  max-h-[300px]">
            {collections.map((collection, index) => {
              return (
                <p
                  className={
                    currentCollection === collection
                      ? "text-2xl xl:text-3xl font-rubber text-yellow"
                      : "text-xl xl:text-2xl font-rubber text-white"
                  }
                  onClick={() => handleCollectionSwitch(collection)}
                  key={index}>
                  {collection}
                </p>
              );
            })}
          </div>
          <div className="w-[80%] mt-auto mx-auto">
            <img className="w-full" src={duckyLogo} />
            <div className="flex justify-center items-center mt-5">
              <img
                onClick={() => window.open("https://t.me/RubberDuckyCoin")}
                className="h-[20px] mr-2"
                src={telegram}
              />
              <img
                onClick={() => window.open("https://twitter.com/RubberDuckyCoin")}
                className="h-[20px] ml-2"
                src={instaLogo}
              />
            </div>
          </div>
        </div>
        <div className="lg:w-4/5 h-full flex flex-col justify-between xl:grid xl:grid-cols-2 gap-3 lg:gap-6 ">
          <div
            ref={imgRef}
            className="xl:w-4/5 mx-auto flex flex-col gap-5 justify-center h-full">
            <Canvas
              baseImageURL={tokenURL}
              selectedMemeURL={selectedMeme.memeURL}
              setOverlayTransformations={setOverlayTransformations}
            />

            <div className="flex flex-col justify-center items-center gap-5">
              <div className="flex gap-5">
                {showMemeButton && (
                  <button
                    className="bg-yellow  w-[60%] min-w-max rounded-full font-rubber text-black p-3 text-2xl uppercase disabled:bg-opacity-50 hover:bg-opacity-80"
                    onClick={() => setMemeToggle(!memeToggle)}>
                      ADD DUCKY MEME
                  </button>
                )}
                <button
                  className="bg-black w-[60%] min-w-max rounded-full font-rubber text-yellow p-3 text-2xl uppercase disabled:bg-opacity-50 hover:bg-opacity-80"
                  onClick={() => clearCanvas()}>
                    CLEAR CANVAS
                </button>
              </div>
              <div className="w-full flex justify-center items-center">
                <input
                  className="bg-white lg:text-md font-rubber text-gray py-4 px-6 flex-shrink w-full rounded-l-xl"
                  type="number"
                  placeholder="search by token ID"
                  tokenid={tokenid}
                  onChange={(e) => handleChange(e)}
                />
                <button
                  className="bg-yellow rounded-xl rounded-l-none text-black py-3 px-6 text-2xl font-rubber uppercase hover:bg-red-light hover:opacity-50 min-w-max"
                  onClick={() => renderWithTokenid()}>
                  search
                </button>
              </div>
              <div className="flex justify-center items-center">
                <button
                  className="bg-rubberblue w-[60%] min-w-max rounded-full font-rubber text-black p-3 text-2xl uppercase disabled:bg-opacity-50 hover:bg-opacity-80"
                  disabled={false}
                  onClick={() => handleDownloadAndTweet()}>
                  Download & tweet
                </button>
              </div>
            </div>
          </div>
          <div className="h-1/4 xl:h-full relative flex-grow overflow-hidden">
            <div className="flex h-full w-full justify-center items-center overflow-auto">
              <div className="w-full flex flex-col items-center px-2 xl:px-10">
                {memeToggle || !showMemeButton ? (
                  <div className="flex flex-col items-center justify-center h-full w-full">
                    <div className="w-[60%] gap-5 overflow-y-scroll" style={{maxHeight: 'calc(100vh - 200px)'}}>
                      {allMemes.map((meme, index) => {
                        const isSelected = selectedMeme?.id === meme.id;
                        return (
                          <div className="mb-5">
                            {isSelected ? (
                              <img src={meme.memeURL} onClick={() => { renderWithMeme(meme); }} className="w-full cursor-pointer border-4 border-black p-1 bg-white" key={index} />
                            ) : (
                              <img src={meme.memeURL} onClick={() => { renderWithMeme(meme); }} className="w-full cursor-pointer border active:border-primary-red focus:border-primary-red p-1 bg-white" key={index} />
                            )}
                            <p className="text-xs md:text-lg text-center font-bold text-dark-gray">
                              {meme.memeName}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default App;
