import { fabric } from "fabric";
import React, { useEffect, useRef, useState } from "react";

const Canvas = ({ baseImageURL, selectedMemeURL, setOverlayTransformations }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [fabricCanvas, setFabricCanvas] = useState(null);

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted
    const canvas = new fabric.Canvas(canvasRef.current, {
      width: containerRef.current.offsetWidth * 0.95,
      height: containerRef.current.offsetWidth * 0.95,
    });
    setFabricCanvas(canvas);

    fabric.Image.fromURL(baseImageURL, (img) => {
      if (!isMounted) return; // Guard against operation if component has unmounted
      const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
      img.set({
        originX: "left",
        originY: "top",
        scaleX: scale,
        scaleY: scale,
        selectable: false,
      });
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
    });

    if (selectedMemeURL) {
      const scaleFactor = canvas.width / 1.5 / 1000;
      fabric.Image.fromURL(selectedMemeURL, function (img) {
        if (!isMounted) return; // Guard against operation if component has unmounted
        img.set({
          left: 0,
          top: 0,
          scaleX: scaleFactor,
          scaleY: scaleFactor,
          angle: 0,
          cornersize: 10,
          selectable: true,
          hasControls: true,
        });
        canvas.add(img);
        canvas.setActiveObject(img);

        img.on("modified", function () {
          // Adjust transformation data with scaleFactor
          const transformations = {
            left: img.left / scaleFactor / 1.5,
            top: img.lineCoords.tr.y / scaleFactor / 1.5,
            scaleX: img.scaleX / scaleFactor / 1.5, // Scale might not need adjustment if it's a multiplier
            scaleY: img.scaleY / scaleFactor / 1.5, // Same as scaleX
            angle: img.angle,
          };
          setOverlayTransformations(transformations);
        });
      });
    }

    // Cleanup function to dispose of the fabric canvas and cancel pending operations
    return () => {
      isMounted = false; // Indicate component has unmounted
      canvas.dispose();
    };
  }, [baseImageURL, selectedMemeURL]);

  return (
    <div className="flex justify-center items-center h-full w-full" ref={containerRef}>
      <canvas ref={canvasRef} id="memeCanvas" />
    </div>
  );
};

export default Canvas;
